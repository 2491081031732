import React from "react";
import { Link } from "gatsby";

import { Text, getProperty } from "@zeus/shared";

import { useHeader } from "hooks";

import * as styles from "./nav-items.module.scss";

export const NavItems = () => {
  const header = useHeader();
  const items = getProperty(header, "nav.items") || [];

  return (
    <nav className={styles.wrapper}>
      {items.map((page) => {
        const path = getProperty(page, "path");
        const text = getProperty(page, "text");

        return (
          <Link key={path} to={path} className={styles.item}>
            <Text size="s8" transform="uppercase" wrapper="span">
              {text}
            </Text>
          </Link>
        );
      })}
    </nav>
  );
};
