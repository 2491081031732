import React from "react";

import { Container } from "@zeus/shared";

import { Nav } from "./nav";
import { Disclaimer } from "./disclaimer";

import * as styles from "./bottom.module.scss";

export const Bottom = () => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <div className={styles.nav}><Nav /></div>
        <Disclaimer />
      </Container>
    </div>
  );
};
