import React from "react";

import { LocaleProvider, ScrollProvider, ThemeProvider } from "@zeus/shared";

import { useMeta } from "hooks";

const Theme = ({ children }) => {
  const meta = useMeta();

  return <ThemeProvider initialMeta={meta}>{children}</ThemeProvider>;
};

const wrapRootElement = ({ element }) => (
  <LocaleProvider>
    <ScrollProvider>
      <Theme>{element}</Theme>
    </ScrollProvider>
  </LocaleProvider>
);

export default wrapRootElement;
