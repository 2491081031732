"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toUri = exports.isValid = void 0;

var isValid = function isValid(value) {
  if (typeof value !== "string") {
    return false;
  }

  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
};

exports.isValid = isValid;

var toUri = function toUri(value) {
  return isValid(value) ? "mailto:".concat(value) : null;
};

exports.toUri = toUri;