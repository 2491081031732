import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Text } from "@zeus/shared";

import * as styles from "./contact.module.scss";

const LAYOUT_SMALL = "small";
const LAYOUT_LARGE = "large";

export const Contact = ({ layout, url, text, icon: Icon, ...props }) => {
  const wrapperClassName = useMemo(() => [styles.wrapper, styles[layout]].join(" "), [layout]);

  return (
    <a href={url} className={wrapperClassName} {...props}>
      <Icon className={styles.icon} />
      {layout === LAYOUT_LARGE && (
        <Text size="s3" transform="uppercase" className={styles.text}>
          {text}
        </Text>
      )}
    </a>
  );
};

Contact.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  layout: PropTypes.oneOf([LAYOUT_SMALL, LAYOUT_LARGE]).isRequired,
};
