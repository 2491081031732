import React from "react";

import { PhoneIcon, toPhoneUri, getProperty } from "@zeus/shared";

import { useMeta } from "hooks";

import { Contact } from "./contact";

const Phone = ({ layout }) => {
  const meta = useMeta();
  const url = getProperty(meta, "contact.phone.url");
  const text = getProperty(meta, "contact.phone.text");

  return <Contact url={toPhoneUri(url)} text={text} icon={PhoneIcon} layout={layout} />;
};

export const PhoneSmall = () => <Phone layout="small" />;
export const PhoneLarge = () => <Phone layout="large" />;
