import React from "react";

import { NavItems } from "./nav-items";

import * as styles from "./nav.module.scss";

export const Nav = () => (
  <nav className={styles.wrapper}>
    <NavItems />
  </nav>
);
