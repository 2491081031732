import React from "react";

import { getProperty, toEmailUri, EmailIcon, InstagramIcon, FacebookIcon } from "@zeus/shared";

import { useMeta } from "hooks";

import * as styles from "./social-media.module.scss";

export const SocialMedia = () => {
  const meta = useMeta();

  const email = getProperty(meta, "contact.email.url");
  const instagram = getProperty(meta, "social_media.instagram.url");
  const facebook = getProperty(meta, "social_media.facebook.url");

  return (
    <div className={styles.wrapper}>
      <a href={toEmailUri(email)} rel="noreferrer">
        <EmailIcon className={styles.icon} />
      </a>
      <a href={instagram} rel="noreferrer" target="_blank">
        <InstagramIcon className={styles.icon} />
      </a>
      <a href={facebook} rel="noreferrer" target="_blank">
        <FacebookIcon className={styles.icon} />
      </a>
    </div>
  );
};
