// extracted by mini-css-extract-plugin
export var breakpoint_xs = "30rem";
export var breakpoint_sm = "48rem";
export var breakpoint_md = "64rem";
export var breakpoint_lg = "75rem";
export var breakpoint_xl = "90rem";
export var color_primary = "#00a3a6";
export var color_secondary = "#070707";
export var wrapper = "top-module--wrapper--T5xYs";
export var contact = "top-module--contact--Wz1GV";
export var phone = "top-module--phone--mdBOz";
export var whatsapp = "top-module--whatsapp--HdasJ";
export var separator = "top-module--separator--TvYwa";