import React from "react";

import { Text, getProperty } from "@zeus/shared";

import { useHeader } from "hooks";

import * as styles from "./appointment.module.scss";

export const Appointment = () => {
  const header = useHeader();

  const text = getProperty(header, "appointment.text");
  const url = getProperty(header, "appointment.url");

  return (
    <a href={url} target="_blank" rel="noreferrer" className={styles.wrapper}>
      <Text size="s3" transform="uppercase">
        {text} &rarr;
      </Text>
    </a>
  );
};
