"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.trimSlashes = exports.resolvePath = exports.normalizePath = exports.joinPaths = exports.isURL = void 0;

var joinPaths = function joinPaths(parts) {
  if (!Array.isArray(parts)) {
    throw new Error("\"paths\" must be an array!");
  }

  parts = parts.map(function (part) {
    return part.trim();
  });
  parts = parts.join("/");
  return resolvePath(parts);
};

exports.joinPaths = joinPaths;

var resolvePath = function resolvePath(urlOrPath) {
  if (typeof urlOrPath !== "string") {
    throw new Error("\"urlOrPath\" must be a string!");
  }

  var resolved = urlOrPath;

  try {
    var _URL = new URL(urlOrPath);

    resolved = _URL.href;
  } catch (e) {
    resolved = normalizePath(urlOrPath);
  }

  return resolved;
};

exports.resolvePath = resolvePath;

var normalizePath = function normalizePath(path) {
  if (typeof path !== "string") {
    throw new Error("\"path\" must be a string!");
  } // resolve relative paths: "abc/../" -> ""


  var regex = /[^/.]+\/+\.\./g;
  var match;

  while (match = path.match(regex)) {
    path = path.replace(match[0], "");
  } // normalize double slashes: "//" -> "/"
  // negative lookbehind to avoid removing
  // slashes in protocols, f.e. http://
  // https://stackoverflow.com/questions/641407


  path = path.replace(/((?![:/]).|^)\/{2,}/g, "$1/"); // remove dot slash paths: "./" -> ""

  path = path.replace(/(?:^\.\/|([^.])\.\/)/g, "$1");
  return path;
};

exports.normalizePath = normalizePath;

var trimSlashes = function trimSlashes(path) {
  if (typeof path !== "string") {
    throw new Error("\"path\" must be a string!");
  }

  return path.replace(/^[/]*|[/]*$/g, "");
};

exports.trimSlashes = trimSlashes;

var isURL = function isURL(value) {
  try {
    new URL(value);
  } catch (e) {
    return false;
  }

  return true;
};

exports.isURL = isURL;