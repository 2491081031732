import React, { useMemo } from "react";

import * as styles from "./hamburger.module.scss";

export const Hamburger = ({ onClick, isActive }) => {
  const wrapperClassName = useMemo(
    () => [styles.wrapper, isActive ? styles.active : ''].join(" "),
    [isActive]
  );

  return (
    <button onClick={onClick} className={wrapperClassName}>
      <span className={styles.lines} />
    </button>
  );
};

Hamburger.defaultProps = {
  isActive: false,
  onClick: () => {},
};
