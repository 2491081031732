import React from "react";

import { Top } from "./footer/top";
import { Bottom } from "./footer/bottom";

export const Footer = () => (
  <footer>
    <Top />
    <Bottom />
  </footer>
);
