"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTransition = void 0;

var _react = require("react");

var _utils = require("../utils");

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var PRE_ENTER = 0;
var ENTERING = 1;
var ENTERED = 2;
var PRE_EXIT = 3;
var EXITING = 4;
var EXITED = 5;
var UNMOUNTED = 6;
var STATES = ["preEnter", "entering", "entered", "preExit", "exiting", "exited", "unmounted"];

var startOrEnd = function startOrEnd(unmounted) {
  return unmounted ? UNMOUNTED : EXITED;
};

var useTransition = function useTransition() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$enter = _ref.enter,
      enter = _ref$enter === void 0 ? true : _ref$enter,
      _ref$exit = _ref.exit,
      exit = _ref$exit === void 0 ? true : _ref$exit,
      preEnter = _ref.preEnter,
      preExit = _ref.preExit,
      timeout = _ref.timeout,
      initialEntered = _ref.initialEntered,
      mountOnEnter = _ref.mountOnEnter,
      unmountOnExit = _ref.unmountOnExit,
      onChange = _ref.onChange;

  var _useState = (0, _react.useState)(initialEntered ? ENTERED : startOrEnd(mountOnEnter)),
      _useState2 = _slicedToArray(_useState, 2),
      state = _useState2[0],
      setState = _useState2[1];

  var latestState = (0, _react.useRef)(state);
  var timeoutId = (0, _react.useRef)();
  var enterTimeout, exitTimeout;

  if ((0, _utils.isObject)(timeout)) {
    enterTimeout = timeout.enter;
    exitTimeout = timeout.exit;
  } else {
    enterTimeout = exitTimeout = timeout;
  }

  var updateState = (0, _react.useCallback)(function (state) {
    clearTimeout(timeoutId.current);
    latestState.current = state;
    setState(state);

    if (typeof onChange === "function") {
      onChange({
        state: STATES[state]
      });
    }
  }, [onChange]);
  var endTransition = (0, _react.useCallback)(function () {
    var newState;

    switch (latestState.current) {
      case ENTERING:
      case PRE_ENTER:
        newState = ENTERED;
        break;

      case EXITING:
      case PRE_EXIT:
        newState = startOrEnd(unmountOnExit);
        break;

      default:
    }

    if (typeof newState !== "undefined") {
      updateState(newState);
    }
  }, [updateState, unmountOnExit]);
  var transitState = (0, _react.useCallback)(function (newState) {
    updateState(newState);

    switch (newState) {
      case ENTERING:
        if (enterTimeout >= 0) timeoutId.current = setTimeout(endTransition, enterTimeout);
        break;

      case EXITING:
        if (exitTimeout >= 0) timeoutId.current = setTimeout(endTransition, exitTimeout);
        break;

      case PRE_ENTER:
      case PRE_EXIT:
        timeoutId.current = setTimeout(function () {
          return transitState(newState + 1);
        }, 0);
        break;

      default:
    }
  }, [updateState, endTransition, enterTimeout, exitTimeout]);
  var toggle = (0, _react.useCallback)(function (toEnter) {
    var enterStage = latestState.current <= ENTERED;
    if (typeof toEnter !== "boolean") toEnter = !enterStage;

    if (toEnter) {
      if (!enterStage) {
        transitState(enter ? preEnter ? PRE_ENTER : ENTERING : ENTERED);
      }
    } else {
      if (enterStage) {
        transitState(exit ? preExit ? PRE_EXIT : EXITING : startOrEnd(unmountOnExit));
      }
    }
  }, [enter, exit, preEnter, preExit, transitState, unmountOnExit]);
  (0, _react.useEffect)(function () {
    return function () {
      return clearTimeout(timeoutId.current);
    };
  }, []);
  return [STATES[state], toggle, endTransition];
};

exports.useTransition = useTransition;