import React, { useRef, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { useTheme, useKeyPress } from "@zeus/shared";

import { Header } from "./header";
import { Footer } from "./footer";

import * as styles from "./layout.module.scss";

export const Layout = ({ children, location }) => {
  const [showMenu, setShowMenu] = useState(false);
  const isEscapePressed = useKeyPress("Escape");
  const { theme } = useTheme();
  const wrapperRef = useRef();

  const wrapperClassName = useMemo(() => [styles.wrapper, styles[theme]].join(" "), [theme]);
  const htmlAttributes = useMemo(
    () => ({
      class: [styles[theme], showMenu ? styles.overflow : ""].filter(Boolean).join(" "),
    }),
    [theme, showMenu]
  );
  const toggleHandler = () => setShowMenu((showMenu) => !showMenu);

  useEffect(() => {
    if (isEscapePressed) {
      setShowMenu(false);
    }
  }, [isEscapePressed]);

  useEffect(() => {
    setShowMenu(false);
  }, [location.hash]);

  return (
    <div className={wrapperClassName} ref={wrapperRef}>
      <Helmet htmlAttributes={htmlAttributes} />
      <Header showMenu={showMenu} onToggle={toggleHandler} />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

Layout.defaultProps = {
  pageContext: {},
};

Layout.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
  }).isRequired,
};
