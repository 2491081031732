// extracted by mini-css-extract-plugin
export var breakpoint_xs = "30rem";
export var breakpoint_sm = "48rem";
export var breakpoint_md = "64rem";
export var breakpoint_lg = "75rem";
export var breakpoint_xl = "90rem";
export var color_primary = "#00a3a6";
export var color_secondary = "#070707";
export var wrapper = "link-rounded-module--wrapper--bQO1s";
export var icon = "link-rounded-module--icon--DpG2q";
export var text = "link-rounded-module--text--tS5HF";
export var container = "link-rounded-module--container--ruEDH";