// extracted by mini-css-extract-plugin
export var breakpoint_xs = "30rem";
export var breakpoint_sm = "48rem";
export var breakpoint_md = "64rem";
export var breakpoint_lg = "75rem";
export var breakpoint_xl = "90rem";
export var color_primary = "#00a3a6";
export var color_secondary = "#070707";
export var wrapper = "navbar-module--wrapper--VJc1V";
export var container = "navbar-module--container--Qd7ad";
export var logo = "navbar-module--logo--yGsty";
export var toggle = "navbar-module--toggle--OJBM3";
export var slogan = "navbar-module--slogan--BGOI5";
export var contact = "navbar-module--contact--Sg9Cw";
export var appointment = "navbar-module--appointment--j3vJQ";
export var phone = "navbar-module--phone--f1Wyd";
export var nav = "navbar-module--nav--I7stq";
export var open = "navbar-module--open--ufKKU";