// extracted by mini-css-extract-plugin
export var breakpoint_xs = "30rem";
export var breakpoint_sm = "48rem";
export var breakpoint_md = "64rem";
export var breakpoint_lg = "75rem";
export var breakpoint_xl = "90rem";
export var color_primary = "#00a3a6";
export var color_secondary = "#070707";
export var uppercase = "text-module--uppercase--dwtTD";
export var lowercase = "text-module--lowercase--DIto5";
export var black = "text-module--black--OAOKX";
export var white = "text-module--white--6znW1";
export var grey = "text-module--grey--FOqSt";
export var primary = "text-module--primary--IiM5P";
export var secondary = "text-module--secondary--vkU0S";
export var s1 = "text-module--s1--8fk6R";
export var s2 = "text-module--s2--KFyca";
export var s3 = "text-module--s3--4wHES";
export var s4 = "text-module--s4--urt-Q";
export var s5 = "text-module--s5--uRbHc";
export var s6 = "text-module--s6--LVGwD";
export var s7 = "text-module--s7--YCcrZ";
export var s8 = "text-module--s8--Smonq";
export var s9 = "text-module--s9--i6tAg";
export var s10 = "text-module--s10---8g62";
export var s11 = "text-module--s11--wjiZa";
export var w1 = "text-module--w1--qfQta";
export var w2 = "text-module--w2--W9kkl";
export var w3 = "text-module--w3--tstYu";
export var w4 = "text-module--w4--iHjag";
export var w5 = "text-module--w5--XIr8p";
export var w6 = "text-module--w6--E+-c-";
export var w7 = "text-module--w7--12Djm";
export var f1 = "text-module--f1--4gH8f";
export var f2 = "text-module--f2--D32QO";