import React, { useMemo } from "react";
import { Link } from "gatsby";

import { LogoTextIcon, Text, getProperty, useScroll } from "@zeus/shared";

import { useHeader } from "hooks";

import * as styles from "./logo.module.scss";

export const Logo = () => {
  const header = useHeader();
  const { scrollY } = useScroll();
  const isSmallLogo = scrollY > 0;

  const wrapperClassName = useMemo(
    () => [styles.wrapper, isSmallLogo ? styles.small : ""].join(" "),
    [isSmallLogo]
  );

  const subtitle = getProperty(header, "logo.subtitle");

  return (
    <Link className={wrapperClassName} to="/">
      <LogoTextIcon className={styles.icon} />
      <Text size="s2" transform="uppercase">
        {subtitle}
      </Text>
    </Link>
  );
};
