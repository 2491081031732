// extracted by mini-css-extract-plugin
export var breakpoint_xs = "30rem";
export var breakpoint_sm = "48rem";
export var breakpoint_md = "64rem";
export var breakpoint_lg = "75rem";
export var breakpoint_xl = "90rem";
export var color_primary = "#00a3a6";
export var color_secondary = "#070707";
export var wrapper = "contact-module--wrapper--zqdGL";
export var small = "contact-module--small--D4RAF";
export var icon = "contact-module--icon--24RPz";
export var large = "contact-module--large--C2Tmm";
export var text = "contact-module--text--Svtis";