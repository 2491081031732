"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ALL_LOCALES", {
  enumerable: true,
  get: function get() {
    return _locale.ALL_LOCALES;
  }
});
Object.defineProperty(exports, "DEFAULT_LOCALE", {
  enumerable: true,
  get: function get() {
    return _locale.DEFAULT_LOCALE;
  }
});
Object.defineProperty(exports, "LocaleContext", {
  enumerable: true,
  get: function get() {
    return _locale.LocaleContext;
  }
});
Object.defineProperty(exports, "LocaleProvider", {
  enumerable: true,
  get: function get() {
    return _locale.LocaleProvider;
  }
});
Object.defineProperty(exports, "MENU_FOOTER", {
  enumerable: true,
  get: function get() {
    return _theme.MENU_FOOTER;
  }
});
Object.defineProperty(exports, "MENU_MAIN", {
  enumerable: true,
  get: function get() {
    return _theme.MENU_MAIN;
  }
});
Object.defineProperty(exports, "MENU_SIDE", {
  enumerable: true,
  get: function get() {
    return _theme.MENU_SIDE;
  }
});
Object.defineProperty(exports, "PageType", {
  enumerable: true,
  get: function get() {
    return _theme.PageType;
  }
});
Object.defineProperty(exports, "ScrollContext", {
  enumerable: true,
  get: function get() {
    return _scroll.ScrollContext;
  }
});
Object.defineProperty(exports, "ScrollProvider", {
  enumerable: true,
  get: function get() {
    return _scroll.ScrollProvider;
  }
});
Object.defineProperty(exports, "THEME_DARK", {
  enumerable: true,
  get: function get() {
    return _theme.THEME_DARK;
  }
});
Object.defineProperty(exports, "THEME_LIGHT", {
  enumerable: true,
  get: function get() {
    return _theme.THEME_LIGHT;
  }
});
Object.defineProperty(exports, "ThemeContext", {
  enumerable: true,
  get: function get() {
    return _theme.ThemeContext;
  }
});
Object.defineProperty(exports, "ThemeProvider", {
  enumerable: true,
  get: function get() {
    return _theme.ThemeProvider;
  }
});

var _locale = require("./locale");

var _scroll = require("./scroll");

var _theme = require("./theme");