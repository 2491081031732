import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Container } from "@zeus/shared";

import { Hamburger } from "./hamburger";
import { Logo } from "./logo";
import { Slogan } from "./slogan";
import { Nav } from "./nav";

import { Appointment, PhoneSmall } from "../common";

import * as styles from "./navbar.module.scss";

export const Navbar = ({ onToggle, isOpen }) => {
  const wrapperClassName = useMemo(
    () => [styles.wrapper, isOpen ? styles.open : ""].filter(Boolean).join(" "),
    [isOpen]
  );

  return (
    <div className={wrapperClassName}>
      <Container>
        <div className={styles.container}>
          <div className={styles.toggle}>
            <Hamburger onClick={onToggle} isActive={isOpen} />
          </div>

          <div className={styles.slogan}>
            <Slogan />
          </div>

          <div className={styles.logo}>
            <Logo />
          </div>
          <div className={styles.contact}>
            <div className={styles.appointment}>
              <Appointment />
            </div>
            <div className={styles.phone}>
              <PhoneSmall />
            </div>
          </div>
          <div className={styles.nav}>
            <Nav isOpen={isOpen} onToggle={onToggle} />
          </div>
        </div>
      </Container>
    </div>
  );
};

Navbar.defaultProps = {
  isOpen: false,
  onToggle: () => {},
};

Navbar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};
