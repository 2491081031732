"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.XS = exports.XL = exports.SM = exports.MD = exports.LG = void 0;

var _variablesModule = require("../styles/_variables.module.scss");

// parse SASS variables and convert rem to px: "1rem" -> 16
var XS = 16 * parseFloat(_variablesModule.breakpoint_xs);
exports.XS = XS;
var SM = 16 * parseFloat(_variablesModule.breakpoint_sm);
exports.SM = SM;
var MD = 16 * parseFloat(_variablesModule.breakpoint_md);
exports.MD = MD;
var LG = 16 * parseFloat(_variablesModule.breakpoint_lg);
exports.LG = LG;
var XL = 16 * parseFloat(_variablesModule.breakpoint_xl);
exports.XL = XL;