import React from "react";

import { Text, getProperty } from "@zeus/shared";

import { useHeader } from "hooks";

export const Slogan = () => {
  const header = useHeader();

  const slogan = getProperty(header, "slogan");

  return (
    <Text size="s3" transform="uppercase">
      {slogan}
    </Text>
  );
};
