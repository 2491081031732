import { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { getProperty } from "@zeus/shared";

export const MENU_MAIN = "main";
export const MENU_MISC = "misc";

export const usePages = () => {
  const ref = useRef(null);
  const key = "allMarkdownRemark.edges";
  const results = useStaticQuery(query) || {};
  const edges = getProperty(results, key) || [];

  if (ref.current === null) {
    ref.current = edges.map((edge) => getProperty(edge, "node.frontmatter"));
  }

  return ref.current;
};

export const usePagesByMenu = (value) =>
  usePages().filter((page) => {
    const menu = getProperty(page, "menu") || [];
    return menu.includes(value);
  });

const query = graphql`
  query usePagesQuery {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "page" } } }
      sort: { fields: [frontmatter___rank], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            path
            menu
            title
          }
        }
      }
    }
  }
`;
