// extracted by mini-css-extract-plugin
export var breakpoint_xs = "30rem";
export var breakpoint_sm = "48rem";
export var breakpoint_md = "64rem";
export var breakpoint_lg = "75rem";
export var breakpoint_xl = "90rem";
export var color_primary = "#00a3a6";
export var color_secondary = "#070707";
export var wrapper = "button-module--wrapper--9r4xk";
export var text = "button-module--text--VSSqd";
export var black = "button-module--black--MifFa";
export var white = "button-module--white--TjjTm";
export var icon = "button-module--icon--92eSN";