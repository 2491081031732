"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setProperty = exports.removeProperty = exports.isObject = exports.getProperty = void 0;

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }

function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var isObject = function isObject(value) {
  return _typeof(value) === "object" && value !== null;
};

exports.isObject = isObject;

var getProperty = function getProperty(object, path) {
  if (!isObject(object)) {
    return null;
  }

  if (typeof path !== "string") {
    return null;
  }

  return path.split(".").reduce(function (result, key) {
    return isObject(result) ? result[key] : result;
  }, object);
};

exports.getProperty = getProperty;

var setProperty = function setProperty(object, path, value) {
  if (!isObject(object)) {
    return;
  }

  if (typeof path !== "string") {
    return;
  }

  if (typeof value === "undefined") {
    return;
  }

  path = path.split(".");
  return path.reduce(function (result, key, index) {
    if (index === path.length - 1) {
      result[key] = value;
      return value;
    }

    if (!isObject(result[key])) {
      result[key] = {};
    }

    return result[key];
  }, object);
};

exports.setProperty = setProperty;

var removeProperty = function removeProperty(object, property) {
  if (!isObject(object)) {
    return;
  }

  if (typeof property !== "string") {
    return;
  }

  var _ = object[property],
      rest = _objectWithoutProperties(object, [property].map(_toPropertyKey));

  return rest;
};

exports.removeProperty = removeProperty;