import React from "react";

import { Container, FlashIcon } from "@zeus/shared";

import { PhoneLarge, WhatsAppLarge, SocialMedia } from "../common";

import * as styles from "./top.module.scss";

export const Top = () => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <div className={styles.contact}>
          <div className={styles.phone}>
            <PhoneLarge />
          </div>
          {/*<div className={styles.whatsapp}>
            <WhatsAppLarge />
          </div>*/}
        </div>
        <FlashIcon className={styles.separator} />
        <SocialMedia />
      </Container>
    </div>
  );
};
