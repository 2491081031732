import React from "react";
import { Link } from "gatsby";

import { Text, getProperty } from "@zeus/shared";

import { usePagesByMenu, MENU_MISC } from "hooks";

import * as styles from "./nav-items.module.scss";

export const NavItems = () => {
  const pages = usePagesByMenu(MENU_MISC);

  return pages.map((page) => {
    const path = getProperty(page, "path");
    const title = getProperty(page, "title");

    return (
      <Link key={path} to={path} className={styles.item} activeClassName={styles.active}>
        <Text size="s2" transform="uppercase" wrapper="span">
          {title}
        </Text>
      </Link>
    );
  });
};
