"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.COLOR_TERTIARY = exports.COLOR_SECONDARY = exports.COLOR_PRIMARY = void 0;

var _variablesModule = require("../styles/_variables.module.scss");

var COLOR_PRIMARY = _variablesModule.color_primary;
exports.COLOR_PRIMARY = COLOR_PRIMARY;
var COLOR_SECONDARY = _variablesModule.color_secondary;
exports.COLOR_SECONDARY = COLOR_SECONDARY;
var COLOR_TERTIARY = _variablesModule.color_tertiary;
exports.COLOR_TERTIARY = COLOR_TERTIARY;