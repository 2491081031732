import React from "react";

import { NavItems } from "./nav-items";

import * as styles from "./nav.module.scss";

export const Nav = () => (
  <div className={styles.wrapper}>
    <NavItems />
  </div>
);
